import energieImage from "./images/rinnovabili.jpeg";
import ingegneriaImage from "./images/area_vasta.jpeg";
import pianificazioneImage from "./images/Management.jpeg";
import costruzioniCiviliImage from "./images/Costruzioni.jpeg";
import costruzioniIndustrialiImage from "./images/Costruzioni.jpeg";
import oilGasImage from "./images/oil&gas.jpeg";
import financialPlanningImage from "./images/Finanza.jpeg";

export const sectorsDetail = [
    {
        id: 1,
        title: "Energie Rinnovabili",
        image: energieImage,
        description: "In Forward, crediamo che il futuro dell'energia sia sostenibile e accessibile. Siamo impegnati a progettare soluzioni innovative che ottimizzino l'uso delle risorse naturali e riducano l'impatto ambientale, contribuendo alla transizione energetica globale. Offriamo servizi di consulenza, progettazione e realizzazione di impianti per la produzione di energia da fonti rinnovabili come il solare, l’eolico, l’idroelettrico e la biomassa.\n" +
            "Il nostro team di ingegneri altamente specializzati lavora a stretto contatto con i clienti per analizzare le esigenze specifiche e individuare le soluzioni più efficienti, sia per progetti su larga scala che per impianti più piccoli. Grazie a una combinazione di competenze tecniche avanzate e profonda conoscenza del mercato, ci impegniamo a garantire che ogni progetto sia allineato con le normative vigenti e massimizzi l'efficienza energetica e la redditività.\n" +
            "Forward si distingue anche per il suo approccio integrato: ci occupiamo dell’intero ciclo di vita del progetto, dalla fase di studio di fattibilità fino alla messa in opera e alla manutenzione dell’impianto, con un forte focus sull'innovazione e sulla sostenibilità. Collaboriamo con partner e istituzioni leader nel settore delle rinnovabili per sviluppare tecnologie all'avanguardia e contribuire a un futuro più verde per le prossime generazioni.\n",
        subsectors: [
            {
                id: 1,
                title: "Energia Solare",
                description: "Progettazione e installazione di impianti fotovoltaici e solari termici.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Energia Eolica",
                description: "Sviluppo e gestione di parchi eolici onshore e offshore.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Energia Idroelettrica",
                description: "Progettazione e realizzazione di impianti idroelettrici e opere di regolazione delle acque.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Biomassa e Biogas",
                description: "Produzione e gestione di impianti per la conversione di biomassa in energia e il trattamento dei rifiuti organici per la produzione di biogas.",
                image: "https://placehold.co/600x400"
            }
        ]
    },
    {
        id: 2,
        title: "Ingegneria Ambientale e del Territorio",
        image: ingegneriaImage,
        description: "In Forward, ci impegniamo a creare soluzioni ingegneristiche che rispettino l’equilibrio naturale del territorio e tutelino l’ambiente per le generazioni future. Offriamo servizi completi di consulenza e progettazione per interventi che riducono l’impatto ambientale e promuovono la sostenibilità. Sviluppiamo piani integrati per la gestione delle risorse naturali, il controllo dell’inquinamento e la protezione del suolo, dell’aria e delle risorse idriche.\n" +
            "Il nostro team di esperti lavora in sinergia con enti pubblici, aziende e comunità locali per progettare infrastrutture resilienti e sostenibili, gestendo anche valutazioni di impatto ambientale e monitoraggio ecologico. Grazie alla nostra profonda conoscenza normativa e all’utilizzo di tecnologie avanzate, offriamo soluzioni su misura per affrontare sfide come l’erosione costiera, la gestione dei rifiuti, il recupero di siti contaminati e la tutela della biodiversità.\n" +
            "Forward si distingue per un approccio olistico che integra l’analisi tecnica con l’aspetto socio-ambientale, assicurando che ogni progetto risponda agli obiettivi di sostenibilità e rispetti il delicato equilibrio degli ecosistemi. Attraverso l'innovazione e la ricerca, ci poniamo come partner affidabile per progetti che favoriscono uno sviluppo territoriale rispettoso dell’ambiente e sostenibile nel lungo periodo.\n",
        subsectors: [
            {
                id: 1,
                title: "Gestione delle Risorse Idriche",
                description: "Progettazione di sistemi di approvvigionamento, distribuzione e trattamento delle acque.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Bonifica Ambientale",
                description: "Interventi di recupero e bonifica di siti contaminati e gestione dei rifiuti.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Pianificazione Urbanistica",
                description: "Progettazione e regolamentazione degli spazi urbani e territoriali.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Monitoraggio Ambientale",
                description: "Valutazione e controllo della qualità dell'aria, dell'acqua e del suolo.",
                image: "https://placehold.co/600x400"
            }
        ]
    },
    {
        id: 3,
        title: "Pianificazione Territoriale e Area Vasta",
        image: pianificazioneImage,
        description: "Forward è il partner ideale per affrontare le sfide complesse della pianificazione territoriale su ampia scala, con un approccio orientato allo sviluppo sostenibile e alla valorizzazione delle risorse locali. Offriamo servizi completi di consulenza e progettazione per piani regolatori, sviluppo urbano, gestione delle aree rurali e organizzazione territoriale, con particolare attenzione alla coesione tra aree urbane e periferiche.\n" +
            "Grazie alla nostra esperienza multidisciplinare, supportiamo enti pubblici, privati e comunità locali nella creazione di strategie integrate che tengano conto delle necessità economiche, sociali e ambientali del territorio. Utilizziamo tecnologie avanzate di analisi spaziale e sistemi informativi geografici (GIS) per mappare le risorse e individuare le aree di sviluppo ottimale, rispettando al contempo il patrimonio paesaggistico e culturale.\n" +
            "Forward lavora in stretta collaborazione con stakeholder e amministrazioni per assicurare che ogni piano di area vasta sia non solo funzionale ma anche inclusivo, promuovendo il benessere delle comunità. I nostri progetti sono pensati per creare territori più resilienti, accessibili e capaci di affrontare i cambiamenti climatici, con un’attenzione particolare alla sostenibilità e alla qualità della vita. La nostra visione è quella di contribuire a un territorio equilibrato e integrato, dove l'innovazione si armonizza con il rispetto dell'ambiente e delle esigenze dei cittadini.\n",
        subsectors: [
            {
                id: 1,
                title: "Pianificazione Urbanistica",
                description: "Progettazione di piani regolatori e normative per lo sviluppo urbano.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Sviluppo Rurale",
                description: "Iniziative per la valorizzazione delle aree rurali e la promozione di pratiche agricole sostenibili.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Gestione del Territorio",
                description: "Analisi e pianificazione per la gestione sostenibile delle risorse naturali e delle aree protette.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Infrastrutture di Trasporto",
                description: "Progettazione e pianificazione di reti di trasporto pubblico e privato.",
                image: "https://placehold.co/600x400"
            }
        ]
    },
    {
        id: 4,
        title: "Costruzioni Civili",
        image: costruzioniCiviliImage,
        description: "In Forward, siamo specializzati nella progettazione e realizzazione di opere civili che rispondano agli standard più elevati di qualità, sicurezza e sostenibilità. Offriamo un servizio completo, che copre tutte le fasi del progetto, dalla pianificazione e progettazione iniziale fino alla gestione e supervisione della costruzione. Lavoriamo con un team di ingegneri, architetti e tecnici altamente qualificati per garantire che ogni progetto sia conforme alle normative e perfettamente integrato nel contesto urbano o territoriale.\n" +
            "Ci occupiamo di un’ampia gamma di opere, tra cui edifici residenziali, strutture pubbliche, infrastrutture viarie, e opere di urbanizzazione, sempre con un approccio attento all’innovazione e all’efficienza. Utilizziamo le più moderne tecniche di costruzione e materiali avanzati per ottimizzare i tempi e i costi, senza mai compromettere la qualità e la sicurezza delle strutture.\n" +
            "Forward è anche un leader nell'adozione di pratiche sostenibili: i nostri progetti mirano a ridurre l'impatto ambientale, incorporando soluzioni di efficienza energetica e materiali a basso impatto. Lavoriamo a stretto contatto con i nostri clienti per realizzare costruzioni che siano non solo funzionali, ma che contribuiscano a migliorare la qualità della vita delle comunità e a rispettare l’ambiente circostante.\n" +
            "Il nostro obiettivo è quello di costruire spazi che durino nel tempo, rispondendo alle sfide contemporanee e contribuendo allo sviluppo sostenibile del territorio.\n",
        subsectors: [
            {
                id: 1,
                title: "Edifici Residenziali",
                description: "Progettazione e costruzione di abitazioni e complessi residenziali.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Strutture Pubbliche",
                description: "Realizzazione di edifici per la pubblica amministrazione, scuole e ospedali.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Infrastrutture Stradali",
                description: "Progettazione e costruzione di strade, ponti e viadotti.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Opere di Urbanizzazione",
                description: "Sviluppo di aree urbane con reti di servizi, come acqua, luce e fognature.",
                image: "https://placehold.co/600x400"
            },
        ]
    },
    {
        id: 5,
        title: "Costruzioni Industriali",
        image: costruzioniIndustrialiImage,
        description: "In Forward, siamo specializzati nella progettazione e realizzazione di strutture industriali all’avanguardia, pensate per ottimizzare la produttività e rispondere alle specifiche esigenze di ogni cliente. Dalla pianificazione iniziale fino alla consegna chiavi in mano, offriamo un servizio completo che garantisce efficienza, sicurezza e durabilità in ogni progetto. Il nostro team di ingegneri e tecnici qualificati lavora a stretto contatto con le aziende per creare spazi funzionali e innovativi, capaci di adattarsi alle necessità di produzione, logistica e stoccaggio.\n" +
            "Realizziamo un’ampia gamma di strutture, tra cui capannoni, stabilimenti produttivi, magazzini e centri di distribuzione, utilizzando le tecnologie costruttive più avanzate e materiali di qualità certificata. La nostra esperienza ci consente di rispettare rigorosi standard di sicurezza e di integrare soluzioni per l’efficienza energetica e la riduzione dei consumi, contribuendo così a ridurre l’impatto ambientale dei nostri progetti.\n" +
            "Forward adotta un approccio flessibile e su misura, adattando ogni progetto alle caratteristiche specifiche del sito e del settore industriale di riferimento. Attraverso soluzioni ingegneristiche innovative e processi ottimizzati, puntiamo a consegnare strutture industriali moderne, affidabili e pronte a supportare le aziende nel loro percorso di crescita.\n" +
            "Il nostro impegno è garantire costruzioni industriali di qualità che durino nel tempo, favorendo la produttività delle aziende e contribuendo a uno sviluppo industriale sostenibile.\n",
        subsectors: [
            {
                id: 1,
                title: "Stabilimenti di Produzione",
                description: "Progettazione e costruzione di impianti industriali per la produzione di beni.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Magazzini e Logistica",
                description: "Costruzione di centri di distribuzione e magazzini automatizzati.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Impianti di Trattamento",
                description: "Realizzazione di strutture per il trattamento e la gestione dei rifiuti industriali.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Piattaforme Offshore",
                description: "Progettazione e costruzione di impianti per l'estrazione di risorse in mare.",
                image: "https://placehold.co/600x400"
            },
        ]
    },
    {
        id: 6,
        title: "Oil & Gas",
        image: oilGasImage,
        description: "Forward offre servizi avanzati di ingegneria per il settore Oil & Gas, con un focus sulla sicurezza, sull’efficienza e sulla sostenibilità delle infrastrutture energetiche. Supportiamo i nostri clienti in ogni fase del progetto, dalla pianificazione e progettazione degli impianti fino alla costruzione, messa in opera e manutenzione. Grazie al nostro team di ingegneri specializzati e tecnici qualificati, realizziamo infrastrutture complesse, come impianti di estrazione, raffinerie, gasdotti e piattaforme offshore, rispettando i più alti standard di qualità e conformità normativa.\n" +
            "Forward si distingue per la capacità di offrire soluzioni personalizzate, integrate e chiavi in mano, progettate per ottimizzare la produzione e minimizzare i rischi operativi. Utilizziamo tecnologie all’avanguardia e metodi di simulazione avanzati per garantire efficienza e sicurezza in ogni fase operativa, anche in ambienti estremi e contesti ad alto rischio.\n" +
            "Consapevoli delle sfide ambientali del settore, lavoriamo costantemente per adottare soluzioni che riducano l’impatto ecologico, attraverso pratiche di efficienza energetica e gestione responsabile delle risorse. Offriamo inoltre servizi di ispezione e monitoraggio continuo, per assicurare che gli impianti operino sempre in modo sicuro e conforme alle normative ambientali.\n" +
            "Con Forward, i clienti del settore Oil & Gas trovano un partner solido e innovativo, capace di affrontare le complessità dell'industria energetica globale con un approccio responsabile e orientato al futuro.\n",
        subsectors: [
            {
                id: 1,
                title: "Estrazione e Produzione",
                description: "Sviluppo di impianti per l'estrazione di petrolio e gas naturale.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Raffinazione",
                description: "Progettazione e gestione di raffinerie per la trasformazione del petrolio in prodotti finiti.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Trasporto e Distribuzione",
                description: "Costruzione di gasdotti e oleodotti per il trasporto di risorse energetiche.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Impianti Offshore",
                description: "Progettazione e costruzione di piattaforme e strutture per l'estrazione in mare.",
                image: "https://placehold.co/600x400"
            },
        ]
    },
    {
        id: 7,
        title: "Financial Planning",
        image: financialPlanningImage,
        description: "Forward offre servizi di consulenza avanzata in Financial Planning, supportando aziende e organizzazioni nella gestione strategica delle risorse finanziarie per favorire una crescita sostenibile e sicura nel lungo termine. Grazie alla nostra esperienza e alla profonda conoscenza dei mercati, forniamo soluzioni personalizzate per pianificare e ottimizzare investimenti, budget e flussi di cassa, adattandoci alle specifiche esigenze di ogni cliente.\n" +
            "Il nostro approccio integrato combina analisi finanziaria, gestione del rischio e strategie di allocazione del capitale, garantendo un controllo efficace sulle risorse e massimizzando il ritorno sugli investimenti. I nostri esperti di Financial Planning lavorano a stretto contatto con i clienti per definire obiettivi chiari e realistici, costruendo piani finanziari che assicurino stabilità economica e flessibilità operativa anche in mercati volatili.\n" +
            "Offriamo una gamma completa di servizi, che include pianificazione fiscale, analisi di scenario, gestione del rischio e ottimizzazione della struttura finanziaria aziendale. Inoltre, ci impegniamo a fornire un monitoraggio continuo e consulenze aggiornate per adattare i piani finanziari alle evoluzioni del mercato e garantire che siano sempre allineati con gli obiettivi aziendali.\n" +
            "Con Forward, i nostri clienti trovano un partner affidabile e proattivo nel Financial Planning, in grado di affrontare le sfide finanziarie con un approccio strategico e orientato al futuro.\n",
        subsectors: [
            {
                id: 1,
                title: "Pianificazione Fiscale",
                description: "Consulenza per la gestione delle imposte e ottimizzazione fiscale.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 2,
                title: "Analisi di Investimento",
                description: "Valutazione delle opportunità di investimento e gestione del portafoglio.",
                image: "https://placehold.co/600x400"
            },
            {
                id: 3,
                title: "Gestione del Rischio",
                description: "Sviluppo di strategie per minimizzare i rischi finanziari e operativi",
                image: "https://placehold.co/600x400"
            },
            {
                id: 4,
                title: "Pianificazione della Liquidità",
                description: "Monitoraggio e gestione dei flussi di cassa per garantire la sostenibilità finanziaria.",
                image: "https://placehold.co/600x400"
            },
        ]
    }
]