import React from 'react';
import MainLayout from "../../../../Layouts/MainLayout";
import {useParams} from "react-router-dom";
import HeaderSection from "../../../../components/HeaderSection";
import axios from "axios";
import {useMutation, useQuery} from "react-query";
import {proxyUrl} from "../../../../constants/proxyUrl";
import MyMdEditor from "../../../../components/markdown/MyMdEditor";
import SuccessAlert from "../../../../components/SuccessAlert";

const EditProjectPage = () => {

    const {id} = useParams()
    const [value, setValue] = React.useState('')
    const [showSuccessAlert, setShowSuccessAlert] = React.useState(false)

    const {
        data: post,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['blog-posts', id],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-progetti/${id}`);
            setValue(response.data.contenuto)
            return response.data
        }
    })

    const updatePostMutation = useMutation({
        mutationFn: async () => {
            const res = await axios.patch(`${proxyUrl}/post-progetti/${id}`, {
                contenuto: value
            }, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });
            return res.data;
        },
        onSuccess: () => {
            setShowSuccessAlert(true)
            setTimeout(() => {
                setShowSuccessAlert(false)
            }, 2000)
            console.log("Success")
        },
        onError: (error) => {
            console.log(error)
        }
    })

    if (isLoading) return <div>Loading...</div>

    if (isError) return <div>Error fetching data</div>

    return (
        <MainLayout>


            <div className={"p-10 flex flex-col gap-2"}>
                {showSuccessAlert && <SuccessAlert name={"Successo"} message={"Post aggiornato con successo"}/>}
                {/*<SuccessAlert name={"Successo"} message={"Post aggiornato con successo"}/>*/}
                <HeaderSection title={post.titolo}/>
                <MyMdEditor
                    value={value}
                    setValue={setValue}
                />
                <div>
                    <button
                        onClick={() => updatePostMutation.mutate()}
                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                    >
                        Salva
                    </button>
                </div>
            </div>
        </MainLayout>
    );
};

export default EditProjectPage;
