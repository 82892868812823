import React from 'react';
import Manifest from "../../components/Manifest";
import MainLayout from "../../Layouts/MainLayout";
import {Carousel} from "@material-tailwind/react";
import CarouselCard from "../../components/CarouselCard";
import SettoriAttivitaBanner from "../../components/SettoriAttivitaBanner";

const AboutPage = () => {
    return (
        <MainLayout>
            <div className={""}>
                <div className={"flex justify-center"}>
                    <div
                        className="visible bg-clip-text text-transparent lg:text-huge text-7xl font-bold bg-colori py-4">
                        Forward.
                    </div>
                </div>

                <div
                    className={"mx-auto w-full px-2 max-w-7xl text-gray-500 text-xl lg:text-3xl pt-10 lg:pt-20 lg:pb-20 italic"}>
                    Visione a lungo termine, ricerca di un futuro sostenibile e responsabile, attraverso l'adozione di
                    soluzioni innovative e sostenibili che possano migliorare la qualità della vita delle persone e
                    delle comunità in cui operano. Consolidare il nostro successo nel lungo periodo, attraverso
                    l'adozione di strategie di crescita sostenibile e l'investimento in risorse umane e tecnologiche.
                    Promozione dei nostri valori, missione e impegno a fornire soluzioni di alta qualità sostenibili per
                    il bene comune
                </div>

                <div className={"mt-20"}>
                    <SettoriAttivitaBanner/>
                </div>

                <div className={"mx-auto w-full px-2 max-w-7xl flex flex-col gap-20 mt-40"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-20"}>
                        <div className={"flex flex-col gap-8"}>
                            <div className={"text-4xl font-bold leading-10"}>
                                Cerchiamo soluzioni sostenibili. Ma cosa significa?
                            </div>
                            <div className={"flex flex-col gap-4 text-lg"}>
                                <div>
                                    Lavoriamo al fianco dei nostri clienti per la loro redditività assicurando una
                                    crescita continua nel rispetto dell’ambiente.
                                </div>
                                <div>
                                    Questo rappresenta un cambiamento sostenibile. Non si prende in considerazione solo
                                    l'ambiente ma anche i fattori economici e sociali ritenuti importanti per consentire
                                    un circolo virtuoso per l’evoluzione delle attività dei clienti.
                                </div>
                            </div>
                        </div>
                        <div>
                            <Carousel transition={{duration: 1}} className="rounded-xl bg-primary-blue">
                                <CarouselCard
                                    title={"Sedi"}
                                    text={"4 in Italia ed una in Romania"}
                                />
                                <CarouselCard
                                    title={"Esperienza"}
                                    text={"30+ anni di progettazione e pianificazione sostenibile"}
                                />
                                <CarouselCard
                                    title={"Progetti"}
                                    text={"Oltre 2.000 progetti eseguiti"}
                                />
                            </Carousel>
                        </div>
                    </div>
                    <Manifest/>
                </div>
            </div>

        </MainLayout>
    );
};

export default AboutPage;
