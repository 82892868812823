import React from 'react';
import Navbar from "../../../../components/Navbar";
import HeaderSection from "../../../../components/HeaderSection";
import MyMdEditor from "../../../../components/markdown/MyMdEditor";
import Footer from "../../../../components/Footer";
import {useMutation} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../../constants/proxyUrl";
import {Trash} from "lucide-react";

const ProjectFormPage = () => {

    const [contenuto, setContenuto] = React.useState("");
    const [titolo, setTitolo] = React.useState("");
    const [posizione, setPosizione] = React.useState("");
    const [cliente, setCliente] = React.useState("");
    const [intervento, setIntervento] = React.useState("");

    const [galleryImages, setGalleryImages] = React.useState([]);

    const tags = [
        "Ingegneria",
        "Elettrotecnica",
        "Finanza"
    ]
    const [selectedTags, setSelectedTags] = React.useState([]);

    const createPostMutation = useMutation({
    mutationFn: async (postData) => {
        const res = await axios.post(`${proxyUrl}/post-progetti/`, postData, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        });
        return res.data;
    },
    onSuccess: async (data) => {
        setContenuto("");
        setTitolo("");
        const postId = data.id;

        const tagPromises = selectedTags.map(tag =>
            axios.post(`${proxyUrl}/post-progetti/tags`, {
                post_progetti: postId,
                tag: tag
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
        );

        const imagePromises = galleryImages.map(image => {
            const imageData = new FormData();
            imageData.append("post_progetto", postId);
            imageData.append("image", image, image.name);
            return axios.post(`${proxyUrl}/post-progetti/images`, imageData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });
        });

        await Promise.all([...tagPromises, ...imagePromises]);

        setSelectedTags([]);
        setGalleryImages([]);
    },
})

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedTags.length === 0) {
            alert("Seleziona almeno un tag");
            return;
        }

        const postData = new FormData();
        postData.append("titolo", titolo);
        postData.append("contenuto", contenuto);
        postData.append("posizione", posizione);
        postData.append("cliente", cliente);
        postData.append("intervento", intervento);
        postData.append("cover_image", e.target.cover.files[0], e.target.cover.files[0].name);
        createPostMutation.mutate(postData)
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <form className={"flex-grow p-10"} onSubmit={handleSubmit}>
                <div className={"mb-6"}>
                    <HeaderSection title={"Crea un post Progetto"}/>
                </div>

                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4 mb-6"}>
                    <div className="col-span-1">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Titolo
                            </div>
                            <div className="relative">
                                <input
                                    type={"text"}
                                    value={titolo}
                                    onChange={(e) => setTitolo(e.target.value)}
                                    maxLength={255}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder={"Titolo del post"}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Copertina
                            </div>
                            <div className="relative">
                                <input
                                    type="file"
                                    name={"cover"}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder="cover"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Posizione
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name={"posizione"}
                                    value={posizione}
                                    onChange={(e) => setPosizione(e.target.value)}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder="Posizione"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Cliente
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name={"cliente"}
                                    value={cliente}
                                    onChange={(e) => setCliente(e.target.value)}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder="Cliente"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Intervento
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name={"intervento"}
                                    value={intervento}
                                    onChange={(e) => setIntervento(e.target.value)}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder="Intervento"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <MyMdEditor
                    value={contenuto}
                    setValue={setContenuto}
                />

                <div className={"flex flex-col my-4 gap-2"}>
                    <div className={"text-xl font-semibold"}>
                        Seleziona almeno un tag
                    </div>
                    <div className={"flex items-center gap-4"}>
                        {tags.map((tag, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    if (selectedTags.includes(tag)) {
                                        setSelectedTags(selectedTags.filter(t => t !== tag))
                                    } else {
                                        setSelectedTags([...selectedTags, tag])
                                    }
                                }}
                                className={`border p-2 rounded cursor-pointer ${selectedTags.includes(tag) ? "bg-primary-blue text-white" : ""}`}
                            >
                                {tag}
                            </div>
                        ))}
                    </div>

                </div>
                <div className={"flex flex-col gap-2"}>
                    <div className={"text-xl font-semibold"}>
                        Galleria
                    </div>
                    <div className="flex gap-4">
                        <input
                            type="file"
                            name={"gallery"}
                            className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                            placeholder="cover"
                            required
                        />
                        <button
                            type="button"
                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                            onClick={e => {
                                const file = e.target.previousSibling.files[0];
                                if (file) {
                                    setGalleryImages([...galleryImages, file]);
                                }
                            }}
                        >
                            Aggiungi
                        </button>
                    </div>
                </div>

                <div className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 my-4 gap-4"}>
                    {galleryImages.map((image, index) => (
                        <div key={index} className={"border rounded-lg p-2 flex items-center justify-between"}>
                            <div>
                                {image.name}
                            </div>
                            <Trash
                                size={20}
                                className={"text-red-600 cursor-pointer"}
                                onClick={() => {
                                    setGalleryImages(galleryImages.filter((img, i) => i !== index));
                                }}
                            />
                        </div>
                    ))}
                </div>

                <div className="col-span-2 text-center mt-6">
                    <button
                        type="submit"
                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                        disabled={createPostMutation.isLoading}
                    >
                        {createPostMutation.isLoading ? "Caricamento..." : "Crea"}
                    </button>
                </div>
            </form>
            <Footer/>
        </div>
    );
};

export default ProjectFormPage;
