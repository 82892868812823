import React from 'react';
import HeaderSection from "../../../components/HeaderSection";
import FormCard from "../../../components/FormCard";
import MainLayout from "../../../Layouts/MainLayout";
import {File, PlusIcon} from "lucide-react";


const SezioneCMS = () => {
    return (
        <MainLayout>
            <section className={"bg-white p-20"}>
                <HeaderSection title={"Gestione Dati"}/>
                <div
                    className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 max-w-screen-lg mt-10 gap-4 ">

                    {/*<FormCard icon={<PlusIcon size={48}/>} title={"Aggiungi Post"} link={"/add_post_cms"}/>*/}
                    <FormCard icon={<PlusIcon size={48}/>} title={"Aggiungi Progetto"} link={"/add_project"}/>
                    <FormCard icon={<File size={48}/>} title={"Careers"} link={"/gestione-careers"}/>
                </div>
            </section>
        </MainLayout>
    );
};

export default SezioneCMS;
