import React from 'react';
import {Link, useParams} from "react-router-dom";
import MainLayout from "../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../constants/proxyUrl";
import {sectorsDetail} from "../../../data/sectors/sectorsDetail";

const SettorePage = () => {

    const {settore} = useParams();

    const content = sectorsDetail.find((content) => content.title === settore);

    const {
        data: projects,
        isLoading: projectsLoading,
        isError: projectsError,
    } = useQuery({
        queryKey: ["projects", settore],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-progetti/`);
            return response.data
        },
    })

    if (projectsLoading) return <div>Loading...</div>
    if (projectsError) return <div>Error...</div>

    return (
        <MainLayout>
            <div className={"my-10 mx-auto w-full px-2 max-w-7xl flex flex-col"}>
                <div className={"flex flex-col gap-8"}>
                    <div className={"flex flex-col"}>
                        <div className={"text-3xl font-bold"}>
                            {content.title}
                        </div>
                        <div className={"italic text-gray-500"}>
                            {content.description}
                        </div>
                    </div>
                    <img src={content.image} alt={content.title} className={"flex object-cover rounded-lg"}/>

                    <div className={"flex flex-col"}>
                        <div className={"text-xl font-semibold"}>
                            {content.subtitle}
                        </div>
                        <div>
                            {content.body}
                        </div>
                    </div>

                    <div className={"flex flex-col gap-4"}>
                        <div className={"text-2xl font-bold"}>
                            Sottosettori
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"}>
                            {
                                content.subsectors.map((subsector) => (
                                    <div className={"flex flex-col gap-2"} key={subsector.id}>
                                        <img src={subsector.image} alt={""} className={"rounded-lg"}/>
                                        <div className={"font-semibold"}>
                                            {subsector.title}
                                        </div>
                                        <div className={"text-gray-500"}>
                                            {subsector.description}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className={"flex flex-col gap-4"}>
                        <div className={"text-2xl font-bold"}>
                            I nostri progetti
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"}>
                            {projects.map((project) => (
                                <Link to={`/project/${project.id}`} key={project.id} className={"cursor-pointer"}>
                                    <div className={"border p-4 rounded-lg"}>
                                        <img src={project.cover_image} alt={""}
                                             className={"object-cover rounded p-2"}/>
                                        <div className={"text-lg font-semibold mt-2"}>
                                            {project.titolo}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>);
};

export default SettorePage;