import React from 'react';
import {Link} from "react-router-dom";

const FeatureLeft = ({title, description, image, link}) => {
    return (
        <div className={`relative pt-10 pb-32 bg-white`}>
            <div className="container flex flex-col lg:flex-row items-center justify-center">
                {/*Image*/}
                <div className={"flex lg:relative lg:left-24 justify-center flex-1"}>
                    <img src={image} alt="" className={" lg:rounded-sm"}/>
                </div>
                {/*Content*/}
                <div
                    className="lg:relative lg:-left-60 lg:-bottom-24 lg:rounded-sm flex flex-1 flex-col items-center text-center lg:items-start bg-primary-blue  backdrop-filter backdrop-blur-xl bg-opacity-80 lg:bg-opacity-40 p-10">
                    <h1 className={"text-3xl text-my-white uppercase font-bold"}>{title}</h1>
                    <p className={"text-my-white my-4 text-center lg:text-left sm:w-3/4 lg:w-full"}>
                        {description}
                    </p>

                    <div>
                        <Link to={"/lettera"}>
                            <button
                                type={"button"}
                                className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-my-white text-black hover:bg-primary-blue hover:text-my-white"}
                            >
                                Scopri di più
                            </button>

                        </Link>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureLeft;
