import React from 'react';

const CarouselCard = ({title, text}) => {
    return (
        <div className={"flex flex-col bg-primary-blue"}>
            <img
                src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
                alt="image 1"
                className="w-full object-cover h-64"
            />
            <div className={"p-2 text-white flex flex-col items-center pt-4 pb-20"}>
                <div className={"text-3xl font-bold"}>
                    {title}
                </div>
                <div>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default CarouselCard;