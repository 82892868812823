import React from 'react';
import {Link} from "react-router-dom";
import {sectorsDetail} from "../data/sectors/sectorsDetail";
const SettoriAttivitaBanner = () => {

    return (
        <div className={"mx-auto w-full px-2 max-w-7xl flex flex-col"}>
            <div className={"text-3xl font-bold mb-10"}>
                Settori di attività
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"}>
                {sectorsDetail.map((sector) => (
                    <div
                        className={"flex flex-col gap-2 border rounded-lg p-4 bg-white shadow"}
                        key={sector.id}
                    >
                        <div className={"h-52 flex"}>
                            <img
                                src={sector.image}
                                className={"rounded-lg object-cover"}
                                alt={""}
                            />
                        </div>

                        <div className={"flex flex-col"}>
                            <div className={"text-2xl font-bold"}>
                                {sector.title}
                            </div>
                            <div className={"mt-2"}>
                                <Link to={`/about/settori/${sector.title}`} className={"text-primary-blue"}>
                                    Scopri di più
                                </Link>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>

    );
};

export default SettoriAttivitaBanner;