import React from 'react';
import imbutt from "../assets/images/imbutt.png";

const Manifest = () => {

    return (
        <div>
            {/*<div className={"bg-stella bg-opacity-30 py-10 mt-20"}>*/}
            {/*    <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-screen-lg">*/}
            {/*        <ManifestCard title={"Analisi"}*/}
            {/*                      description={"Analisi strategica e rottura di vecchi modelli di business per la creazione di nuovi mercati. Cambiamento radicale che modifica le regole del gioco e trasforma il modo in cui le aziende competono e si relazionano con i propri clienti."}/>*/}
            {/*        <ManifestCard title={"Efficacia"}*/}
            {/*                      description={"L' efficacia dipende dalla capacità di risolvere un problema o di sfruttare un'opportunità specifica, di essere realizzabile date le risorse e il contesto, e di essere implementata in modo coerente e misurabile. Una strategia deve essere flessibile, in grado di adattarsi ai cambiamenti del mercato, della concorrenza, delle risorse, e del contesto in cui viene implementata."}*/}
            {/*                      mt={"lg:mt-40"}/>*/}
            {/*        <ManifestCard title={"Innovazione"}*/}
            {/*                      description={"L'innovazione è il processo di creazione e implementazione di nuove idee, prodotti, servizi, processi o modelli di business che offrono valore aggiunto ai consumatori, alle imprese o alla società nel suo insieme."}*/}
            {/*                      mt={"lg:mt-80"}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={"bg-blue-100 bg-opacity-5"}>
                <h1 className={"text-6xl text-primary-blue pt-10"}>Sfide tecniche, progetti e
                    tecnologie</h1>
                <h1 className={"text-3xl text-primary-blue"}>Come utilizzare i big data per rispondere
                    alle sfide</h1>
                <div className="flex flex-col lg:flex-row gap-x-24">
                    {/*Content*/}
                    <div className={"py-10"}>

                        <div className={"bg-gradient-to-r from-red-700 to-white"}>
                            <img
                                src={imbutt}
                                alt=""
                                className={"mix-blend-overlay float-right h-96 ml-10 mb-10"}
                            />
                        </div>

                        <p className={"text-my-gray sm:w-3/4 lg:w-full lg:h-full"}>
                            Oggi, molte imprese raccolgono enormi quantità di dati provenienti da fonti diverse come
                            dispositivi IoT, social media, database aziendali e altri. La sfida consiste nel trovare un
                            modo per estrarre valore da questi dati e tradurli in una comprensione approfondita del
                            business e del mercato.

                            In questo contesto, la statistica e l'analisi dei dati hanno acquisito un'importanza sempre
                            maggiore. L'analisi dei dati consente alle aziende di scoprire le tendenze nascoste e di
                            prendere decisioni informate basate su fatti concreti. Il big data, in particolare, può
                            aiutare a identificare nuove opportunità di mercato, ottimizzare le operazioni aziendali e
                            migliorare l'esperienza del cliente.

                            Dai dati, estraiamo le tendenze nascoste rivelando e trasformando numeri e statistiche in un
                            vantaggio competitivo per la tua azienda. Forward si posiziona come un'azienda in grado di
                            sfruttare le tecnologie di big data, statistica e analisi per ottenere vantaggi competitivi
                            per i propri clienti.
                            Oggi, molte imprese raccolgono enormi quantità di dati provenienti da fonti diverse come
                            dispositivi IoT, social media, database aziendali e altri. La sfida consiste nel trovare un
                            modo per estrarre valore da questi dati e tradurli in una comprensione approfondita del
                            business e del mercato.

                            In questo contesto, la statistica e l'analisi dei dati hanno acquisito un'importanza sempre
                            maggiore. L'analisi dei dati consente alle aziende di scoprire le tendenze nascoste e di
                            prendere decisioni informate basate su fatti concreti. Il big data, in particolare, può
                            aiutare a identificare nuove opportunità di mercato, ottimizzare le operazioni aziendali e
                            migliorare l'esperienza del cliente.

                            Dai dati, estraiamo le tendenze nascoste rivelando e trasformando numeri e statistiche in un
                            vantaggio competitivo per la tua azienda. Forward si posiziona come un'azienda in grado di
                            sfruttare le tecnologie di big data, statistica e analisi per ottenere vantaggi competitivi
                            per i propri clienti.
                        </p>
                    </div>
                </div>
            </div>
            {/*<div className={"invisible lg:visible bg-blue-200 bg-opacity-30"}>*/}
            {/*    <div className={"relative flex items-center justify-center pb-10"}>*/}
            {/*        <img src={graph} alt="" className={"object-cover"}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
};

export default Manifest;
