import React from 'react';
import MainLayout from "../../../../Layouts/MainLayout";
import HeaderSection from "../../../../components/HeaderSection";
import {useQuery} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../../constants/proxyUrl";
import {Link} from "react-router-dom";


const ProjectsPage = () => {

    const {
        data: posts,
        isLoading,
        isError,
        isSuccess
    } = useQuery({
        queryKey: ['blog-posts'],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-progetti/`);
            return response.data
        }
    })

    return (
        <MainLayout>
            <div className={"p-10"}>
                <HeaderSection title={"Progetti"}/>

                {isLoading && <div>Loading...</div>}
                {isError && <div>Error fetching data</div>}
                {isSuccess ? (
                    <div className={"grid grid-cols-1 lg:grid-cols-3 gap-6 my-8"}>
                        {posts.map((post, index) => (
                            <Link key={index} className={"bg-white shadow-md rounded-lg p-4 border flex flex-col justify-between"}
                                  to={`/project/${post.id}`}>
                                <img
                                    src={post.cover_image}
                                    alt={post.titolo}
                                    className={"object-cover rounded-lg"}
                                />
                                <div className={"flex flex-col gap-4 mt-2"}>
                                    <div className={"text-3xl font-bold"}>
                                        {post.titolo}
                                    </div>
                                    <div className={"text-gray-600 flex gap-2"}>
                                        {post.tags.map((tag, index) => (
                                            <div
                                                key={index}
                                                className={"border border-primary-blue text-primary-blue rounded-full px-2 py-1"}>
                                                {tag.tag}
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <button
                                            className={"shadow-md py-3 px-6 rounded-lg transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Di più
                                        </button>
                                    </div>

                                </div>

                            </Link>
                        ))}
                    </div>
                ) : null}
            </div>
        </MainLayout>
    );
};

export default ProjectsPage;
