import React, {useEffect} from 'react';
import ImageBanner from "../../components/ImageBanner";
import FoundedInBanner from "../../components/FoundedInBanner";
import FeatureLeft from "../../components/FeatureLeft";
import GridBlog from "../../components/GridBlog";
import investor from "../../assets/images/monet_ninfee_blu.jpg";
import MainLayout from "../../Layouts/MainLayout";
import ContactBanner from "../../components/ContactBanner";


const mulino = "https://www.startmag.it/wp-content/uploads/maxresdefault1-1.jpg"
const lettera = " La nostra realtà è in continua espansione e ampliamento, per mettere al corrente gli investitori attuali e futuri pubblichiamo le strategie e i piani trimestrali di modo da poter garantire trasparenza e visibilità ai progetti in cantiere.\n" +
    "\n" +
    "Il nostro lavoro parte dalla qualità e creatività dei nostri partner di eccellenza in Italia e in Europa."


const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <MainLayout>
            <ImageBanner
                title={"Forward, scopri chi siamo"}
                description={"Il luogo dove la creatività e l'innovazione incontrano la scienza e la tecnologia per dare vita a soluzioni ingegneristiche che cambiano il mondo."}
                image={mulino}
            />
            {/*<HomeService/>*/}

            <FoundedInBanner/>

            <GridBlog
                title={"le nostre idee in azione"}
                desc1={"Ogni progetto rappresenta una sfida unica, un'opportunità di eccellere e di dimostrare il nostro impegno a costruire un futuro migliore per tutti."}
                desc2={"superare i limiti dell'ingegneria e creare soluzioni innovative"}
            />

            <FeatureLeft
                title={"Piano Industriale"}
                description={lettera}
                image={investor}
                link={"/test"}
            />

            <ContactBanner/>
        </MainLayout>
    );
};

export default HomePage;
