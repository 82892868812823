import React, {useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import MainLayout from "../../../../Layouts/MainLayout";
import {useMutation, useQuery} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../../constants/proxyUrl";
import MyMdEditorPreview from "../../../../components/markdown/MyMdEditorPreview";


const SingleProjectPage = () => {

    const {id} = useParams()

    const {is_superuser} = useSelector(state => state.auth)
    const [selectedImage, setSelectedImage] = useState(null);

    const navigation = useNavigate()

    const eliminaPostMutation = useMutation({
        mutationFn: async () => {
            const response = await axios.delete(`${proxyUrl}/post-progetti/${id}`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );
            return response.data
        },
        onSuccess: () => {
            navigation('/projects')
        }
    })

    const {
        data: post,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['blog-posts', id],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-progetti/${id}`);
            return response.data
        }
    })

    if (isLoading) return <div>Loading...</div>
    if (isError) return <div>Error fetching data</div>

    return (
        <MainLayout
            margin={"-mt-4"}
        >
            <div className={"py-10"}>
                {is_superuser ? (
                    <div className={"flex items-center justify-center py-10 gap-4"}>
                        <Link
                            to={`/edit_project/${id}`}
                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-yellow-600 text-white hover:bg-my-white hover:text-black"}
                        >
                            Modifica
                        </Link>
                        <button
                            onClick={() => eliminaPostMutation.mutate()}
                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-red-600 text-white hover:bg-my-white hover:text-black"}
                        >
                            Elimina
                        </button>
                    </div>
                ) : null}

                <div className={"relative"}>
                    <img
                        src={post.cover_image}
                        alt={post.titolo}
                        className={"w-full h-96 object-cover"}
                    />
                    <div
                        className={"absolute bottom-0 left-1/2 transform w-full -translate-x-1/2 bg-black bg-opacity-70 text-white p-4"}>
                        <div className={"text-4xl font-bold"}>
                            {post.titolo}
                        </div>
                        <div className={"flex gap-2"}>
                            {post.tags.map((tag, index) => (
                                <div
                                    key={index}
                                    className={"border border-white text-white rounded-full px-2 py-1"}>
                                    {tag.tag}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={"mx-auto w-full px-2 max-w-7xl my-10"}>
                    <div className={"grid grid-cols-1 lg:grid-cols-3 gap-10"}>
                        <div className={"flex flex-col gap-10 col-span-1"}>
                            <div>
                                <div className={"text-3xl font-semibold"}>
                                    Cliente
                                </div>
                                <div className={"text-lg text-gray-500"}>
                                    {post.cliente}
                                </div>
                            </div>
                            <div>
                                <div className={"text-3xl font-semibold"}>
                                    Posizione
                                </div>
                                <div className={"text-lg text-gray-500"}>
                                    {post.posizione}
                                </div>
                            </div>
                            <div>
                                <div className={"text-3xl font-semibold"}>
                                    Intervento
                                </div>
                                <div className={"text-lg text-gray-500"}>
                                    {post.intervento}
                                </div>
                            </div>
                        </div>
                        <div className={"lg:col-span-2"}>
                            <MyMdEditorPreview text={post.contenuto}/>
                        </div>
                    </div>
                </div>

                <div className={"grid mx-auto w-full px-2 max-w-7xl my-10 gap-10"}>
                    <div className={"text-4xl font-semibold"}>
                        Gallery
                    </div>
                    <div className={"flex overflow-auto gap-10 pb-20"}>
                        {post.images.map((image, index) => (
                            <img
                                key={index}
                                src={image.image}
                                alt={image.alt}
                                className={"flex object-cover rounded-lg h-64 w-64 cursor-pointer"}
                                onClick={() => setSelectedImage(image.image)}
                            />
                        ))}
                    </div>
                    {selectedImage && (
                        <div className={"fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 py-10"}>
                            <img
                                src={selectedImage}
                                alt="Full Screen"
                                className={"max-w-full max-h-full"}
                            />
                            <button
                                className={"absolute top-4 right-4 text-white text-2xl"}
                                onClick={() => setSelectedImage(null)}
                            >
                                &times;
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </MainLayout>
    );
};

export default SingleProjectPage;
