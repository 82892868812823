import React from 'react';
import Navbar from "../../../../components/Navbar";
import Footer from "../../../../components/Footer";
import MyMdEditor from "../../../../components/markdown/MyMdEditor";
import HeaderSection from "../../../../components/HeaderSection";
import {useMutation} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../../constants/proxyUrl";

const PostCmsPage = () => {

    const [contenuto, setContenuto] = React.useState("");
    const [titolo, setTitolo] = React.useState("");

    const createPostMutation = useMutation({
        mutationFn: async (postData) => {
            const res = await axios.post(`${proxyUrl}/post-blog/`, postData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });
            return res.data;
        },
        onSuccess: () => {
            setContenuto("");
            setTitolo("");
        },
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        const postData = new FormData();
        postData.append("titolo", titolo);
        postData.append("contenuto", contenuto);
        postData.append("cover_image", e.target.cover.files[0], e.target.cover.files[0].name);
        createPostMutation.mutate(postData)
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <form className={"flex-grow p-10"} onSubmit={handleSubmit}>
                <div className={"mb-6"}>
                    <HeaderSection title={"Crea un post per il Blog"}/>
                </div>

                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6"}>
                    <div className="col-span-1">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Titolo
                            </div>
                            <div className="relative">
                                <input
                                    type={"text"}
                                    value={titolo}
                                    onChange={(e) => setTitolo(e.target.value)}
                                    maxLength={255}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder={"Titolo del post"}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="flex flex-col lg:flex-col">
                            <div>
                                Copertina
                            </div>
                            <div className="relative">
                                <input
                                    type="file"
                                    name={"cover"}
                                    className="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none"
                                    placeholder="cover"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <MyMdEditor
                    value={contenuto}
                    setValue={setContenuto}
                />

                <div className="col-span-2 text-center mt-6">
                    <button
                        type="submit"
                        className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                        disabled={createPostMutation.isLoading}
                    >
                        {createPostMutation.isLoading ? "Caricamento..." : "Crea"}
                    </button>
                </div>
            </form>
            <Footer/>
        </div>
    );
};

export default PostCmsPage;
