import React from 'react';
import {Link, useParams} from "react-router-dom";
import MainLayout from "../../../Layouts/MainLayout";
import {useQuery} from "react-query";
import axios from "axios";
import {proxyUrl} from "../../../constants/proxyUrl";

const SettorePage = () => {

    const {settore} = useParams();

    const pageContent = [{
        id: 1,
        title: "Sostenibilità",
        description: "Integriamo soluzioni energeticamente efficienti in tutti i nostri progetti, riducendo l'impatto ambientale.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sagittis sodales justo, a imperdiet tortor elementum nec. Proin in tincidunt urna. Sed justo enim, tincidunt quis dignissim sed, aliquet vel sem. Donec ornare diam leo, in congue orci gravida vel. Donec vel magna orci. Nunc diam erat, ultrices nec sem eget, tristique aliquet metus.",
        image: "https://placehold.co/1000x300",
        subtitle: "Sostenibilità lorem ipsum dolor sit amet",
        body: "Our differentiating capabilities in the advanced manufacturing market include advanced design and engineering in the electrification ecosystem, data centers and semiconductor manufacturing – deployed through a global integrated delivery platform.\n" +
            "\n" +
            "For more than 70 years, we’ve rallied the best teams on the planet to challenge today and reimagine tomorrow. Now, our consultants, architects, engineers and technologists are continuing the story, constantly adding innovations alongside best practice to solve our clients’ most complex problems.\n" +
            "\n" +
            "We plan, select sites, design, engineer, oversee construction and complete operations for clients around the world. We’re committed to building a better world for humanity. This simple shared purpose aligns our people, plans and projects.\n" +
            "\n" +
            "We dream big. We innovate at speed. We remain accountable for end-to-end delivery. And we always find a way, however ‘impossible’ the challenge. Yet it’s pragmatism and expertise that keeps us on track, guided by the highest standards and united by a laser-like dedication to solve our clients’ problems.\n" +
            "\n" +
            "As our clients keep pace with global trends – from climate change and energy resiliency, to tightening regulation to accelerated construction schedules – our integrated project delivery model ensures they are always one step ahead. We are turnkey project-delivery partners for the world’s advanced facilities – offering full-service engineering, procurement and construction management solutions that give our clients greater control, reduce risk and increase value for money."
    }]

    const content = pageContent.find((content) => content.title === settore);

    const {
        data: projects,
        isLoading: projectsLoading,
        isError: projectsError,
    } = useQuery({
        queryKey: ["projects", settore],
        queryFn: async () => {
            const response = await axios.get(`${proxyUrl}/post-progetti/`);
            return response.data
        },
    })

    if (projectsLoading) return <div>Loading...</div>
    if (projectsError) return <div>Error...</div>

    return (
        <MainLayout>
            <div className={"my-10 mx-auto w-full px-2 max-w-7xl flex flex-col"}>
                <div className={"flex flex-col gap-8"}>
                    <div className={"flex flex-col"}>
                        <div className={"text-3xl font-bold"}>
                            {content.title}
                        </div>
                        <div className={"italic text-gray-500"}>
                            {content.description}
                        </div>
                    </div>
                    <img src={content.image} alt={content.title} className={"flex object-cover"}/>

                    <div className={"flex flex-col"}>
                        <div className={"text-xl font-semibold"}>
                            {content.subtitle}
                        </div>
                        <div>
                            {content.body}
                        </div>
                    </div>

                    <div className={"flex flex-col gap-4"}>
                        <div className={"text-2xl font-bold"}>
                            Sottosettori
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"}>
                            <div className={"flex flex-col gap-2"}>
                                <img src={"https://placehold.co/250"} alt={""} className={"rounded-lg"}/>
                                <div className={"text-gray-500"}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic.
                                </div>
                            </div>
                            <div className={"flex flex-col gap-2"}>
                                <img src={"https://placehold.co/250"} alt={""} className={"rounded-lg"}/>
                                <div className={"text-gray-500"}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic.
                                </div>
                            </div>
                            <div className={"flex flex-col gap-2"}>
                                <img src={"https://placehold.co/250"} alt={""} className={"rounded-lg"}/>
                                <div className={"text-gray-500"}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic.
                                </div>
                            </div>
                            <div className={"flex flex-col gap-2"}>
                                <img src={"https://placehold.co/250"} alt={""} className={"rounded-lg"}/>
                                <div className={"text-gray-500"}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"flex flex-col gap-4"}>
                        <div className={"text-2xl font-bold"}>
                            I nostri progetti
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"}>
                            {projects.map((project) => (
                                <Link to={`/project/${project.id}`} key={project.id} className={"cursor-pointer"}>
                                    <div className={"border p-4 rounded-lg"}>
                                        <img src={project.cover_image} alt={""}
                                             className={"object-cover rounded p-2"}/>
                                        <div className={"text-lg font-semibold mt-2"}>
                                            {project.titolo}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>);
};

export default SettorePage;