export const navLink = [
    {
        nome: "Home",
        path: "/"
    },
    {
        nome: "About",
        path: "/about"
    },
    {
        nome: "Progetti",
        path: "/projects"
    },
    {
        nome: "Careers",
        path: "/careers"
    },
    // {
    //     nome: "Blog",
    //     path: "/blog"
    // }
]
