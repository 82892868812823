import React from 'react';
import {Link} from "react-router-dom";

const SettoriAttivitaBanner = () => {


    const tags = [
        {
            id: 1,
            title: "Sostenibilità",
            description: "Integriamo soluzioni energeticamente efficienti in tutti i nostri progetti, riducendo l'impatto ambientale.",
        },
        {
            id: 2,
            title: "Tecnologica",
            description: "Utilizziamo tecnologie all'avanguardia per offrire soluzioni ingegneristiche moderne e sicure.",
        },
        {
            id: 3,
            title: "Affidabilità",
            description: "Garantiamo tempi di consegna e risultati che rispettano le aspettative dei nostri clienti.",
        },
        {
            id: 4,
            title: "Ingegneria",
            description: "La nostra attenzione ai dettagli garantisce progetti di altissima precisione e durabilità.",
        },
        {
            id: 5,
            title: "Energia",
            description: "Promuoviamo l'utilizzo di fonti di energia rinnovabile per un futuro più sostenibile.",
        },
        {
            id: 6,
            title: "Sicurezza",
            description: "La sicurezza sul lavoro e nei progetti è la nostra massima priorità, con protocolli rigorosi.",
        },
        {
            id: 7,
            title: "Collaborazione",
            description: "Lavoriamo a stretto contatto con i nostri clienti per comprendere a fondo le loro esigenze.",
        },
        {
            id: 8,
            title: "Certificazioni",
            description: "Tutti i nostri processi rispettano standard internazionali, garantendo qualità e conformità.",
        },
    ];

    return (
        <div className={"mx-auto w-full px-2 max-w-7xl flex flex-col"}>
            <div className={"text-3xl font-bold mb-10"}>
                Settori di attività
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"}>
                {tags.map((tag) => (
                    <div
                        className={"flex flex-col justify-between gap-2 border rounded-lg p-4 bg-white shadow"}
                        key={tag.id}
                    >
                        <img
                            src={"https://placehold.co/600x400"}
                            className={"rounded-lg"}
                            alt={""}
                        />
                        <div className={"h-full flex flex-col justify-between"}>
                            <div className={"text-2xl font-bold"}>
                                {tag.title}
                            </div>
                            <div className={"mt-2"}>
                                <Link to={`/about/settori/${tag.title}`} className={"text-primary-blue"}>
                                    Scopri di più
                                </Link>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>

    );
};

export default SettoriAttivitaBanner;