import React, {useEffect, useState} from 'react';
import DangerAlert from "../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../components/SuccessAlert";
import BackButton from "../../../../../components/BackButton";
import HeaderSection from "../../../../../components/HeaderSection";
import GenericInput from "../../../../../components/inputs/GenericInput";
import AnagraficaInput from "../../../../../components/inputs/AnagraficaInput";
import SocietaInput from "../../../../../components/inputs/SocietaInput";
import {creaDipendente} from "../../../../../fetch/fetchDipendenti";
import {sleep} from "../../../../../constants/sleep";
import MainLayout from "../../../../../Layouts/MainLayout";
import {MyCombobox} from "../../../../../components/combobox/MyCombobox";
import {getAnagrafiche} from "../../../../../fetch/fetchAnagrafica";

const DipendenteFormPage = () => {

    const [alert, setAlert] = useState(0)

    const [selectedAnagrafica, setSelectedAnagrafica] = useState()
    const [anagrafiche, setAnagrafiche] = useState([])


    const [form, setForm] = useState({
        username: "",
        ruolo: "",
        societa: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault();

        form.anagrafica = selectedAnagrafica.id

        creaDipendente(form).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        }).finally(async () => {
            window.scroll(0, 0)
            await sleep(3000)
            setAlert(0)
        })
    };

    useEffect(() => {
        getAnagrafiche().then(
            r => {
                setAnagrafiche(r.data)
                setSelectedAnagrafica(r.data[0])
            }
        ).catch(
            e => console.log(e)
        )
    }, []);


    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"Il Dipendente è stato aggiunto con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Aggiungi nuovo dipendente"}/>
                <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                    <div
                        className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/*Username*/}
                            <GenericInput
                                label={"Username"}
                                type={"text"}
                                isLong={true}
                                setValue={setForm}
                                value={form}
                                name={"username"}
                            />

                            {/*Anagrafica*/}
                            <div className="col-span-2 lg:col-span-2">
                                <div className={"flex flex-col lg:flex-col"}>
                                    <div className={"text-my-gray"}>
                                        Anagrafica
                                    </div>
                                    <MyCombobox
                                        valueList={anagrafiche}
                                        selectedItem={selectedAnagrafica}
                                        setSelectedItem={setSelectedAnagrafica}
                                    />
                                </div>
                            </div>

                            {/*Ruolo*/}
                            <GenericInput
                                label={"Ruolo"}
                                type={"text"}
                                isLong={true}
                                setValue={setForm}
                                value={form}
                                name={"ruolo"}
                            />

                            {/*Società*/}
                            <SocietaInput value={form} setSelectedValue={setForm}/>

                            {/*Bottone*/}
                            <div className="col-span-2 text-center">
                                <button
                                    type="submit"
                                    className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                >
                                    Registra
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </MainLayout>
    );
};

export default DipendenteFormPage;
