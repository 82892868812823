import React from 'react';
import Manifest from "../../components/Manifest";
import MainLayout from "../../Layouts/MainLayout";
import {Carousel} from "@material-tailwind/react";
import CarouselCard from "../../components/CarouselCard";
import SettoriAttivitaBanner from "../../components/SettoriAttivitaBanner";

const AboutPage = () => {


    // useEffect(() => {
    //     // window.scrollTo(0, 0);
    // }, [])

    return (
        <MainLayout>
            <div className={""}>
                <div className={"flex justify-center"}>
                    <div
                        className="visible bg-clip-text text-transparent lg:text-huge text-7xl font-bold bg-colori py-4">
                        Forward.
                    </div>
                </div>

                <div
                    className={"mx-auto w-full px-2 max-w-7xl text-gray-500 text-xl lg:text-3xl pt-10 lg:pt-20 lg:pb-20 italic"}>
                    Visione a lungo termine, ricerca di un futuro sostenibile e responsabile, attraverso l'adozione di
                    soluzioni innovative e sostenibili che possano migliorare la qualità della vita delle persone e
                    delle comunità in cui operano. Consolidare il nostro successo nel lungo periodo, attraverso
                    l'adozione di strategie di crescita sostenibile e l'investimento in risorse umane e tecnologiche.
                    Promozione dei nostri valori, missione e impegno a fornire soluzioni di alta qualità sostenibili per
                    il bene comune
                </div>

                <div className={"mt-20"}>
                    <SettoriAttivitaBanner/>
                </div>

                <div className={"mx-auto w-full px-2 max-w-7xl flex flex-col gap-20 mt-40"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-20"}>
                        <div className={"flex flex-col gap-2"}>
                            <div className={"text-4xl font-bold leading-10"}>
                                We’re delivering sustainable change. But what does that mean?
                            </div>
                            <div className={"flex flex-col gap-4 text-lg"}>
                                <div>
                                    For us, it’s moving forwards in a measured and well-managed approach; working beside
                                    our
                                    customers to maintain the viability of their assets for continued business growth.
                                </div>
                                <div>
                                    In 2021, we announced our ambition-aligned aspiration of deriving 75 percent of our
                                    revenue
                                    from sustainability-related projects by FY20261.
                                </div>

                                <div>
                                    This represents sustainable change. It’s not just about the environment – it
                                    reflects
                                    economic and social factors, too. It’s about evolving and adapting. All while
                                    maintaining a steady course that will sustain our business through the next 50
                                    years.
                                </div>
                            </div>
                        </div>
                        <div>
                            <Carousel transition={{duration: 1}} className="rounded-xl bg-primary-blue">
                                <CarouselCard/>
                                <CarouselCard/>
                                <CarouselCard/>
                                <CarouselCard/>
                            </Carousel>
                        </div>
                    </div>
                    <Manifest/>
                </div>
            </div>

        </MainLayout>
    );
};

export default AboutPage;
