import React, {useEffect, useState} from 'react';
import DangerAlert from "../../../../../../components/DangerAlert";
import SuccessAlert from "../../../../../../components/SuccessAlert";
import HeaderSection from "../../../../../../components/HeaderSection";
import {getSingolaNotaSpese, modificaNotaSpese} from "../../../../../../fetch/fetchNotaSpese";
import {useParams} from "react-router-dom";
import BackButton from "../../../../../../components/BackButton";
import MainLayout from "../../../../../../Layouts/MainLayout";

const AggiungiNotaSpese = () => {

    const {id} = useParams();
    const [alert, setAlert] = useState(0)

    const [formData, setFormData] = useState({
        titolo: "",
        data: "",
    })

    const {titolo, data} = formData

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value})

    const handleSubmit = (e) => {
        e.preventDefault();

        modificaNotaSpese(id, formData).then(() => {
            setAlert(1)
            e.target.reset()
        }).catch(() => {
            setAlert(-1)
        })
    };

    useEffect(() => {
        getSingolaNotaSpese(id).then((response) => {
            setFormData(response.data)
        }).catch(
            (error) => {
                console.log(error)
            }
        )
    }, []);


    return (
        <MainLayout>
            <div className={"p-10"}>
                {
                    alert === -1 ? (
                        <DangerAlert name={"Errore"} message={"I dati inseriti non sono validi"}/>
                    ) : alert === 1 ? (
                        <SuccessAlert name={"Pubblicato"} message={"La Nota Spese è stato aggiunta con successo"}/>
                    ) : (
                        <div/>
                    )
                }
                <div className={"flex px-20 py-2"}>
                    <BackButton/>
                </div>
                <HeaderSection title={"Modifica Nota Spese"}/>
                <div className={"flex flex-col lg:flex-row justify-evenly"}>
                    <div className={"flex flex-col lg:flex-row"}>
                        <form className="flex container space-x-3 pb-10" onSubmit={handleSubmit}>
                            <div
                                className="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-my-white rounded-sm shadow-sm ">
                                <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                    {/*Titolo*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Titolo
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    placeholder={"Titolo"}
                                                    value={titolo}
                                                    onChange={e => onChange(e)}
                                                    name={"titolo"}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*Data*/}
                                    <div className="col-span-2">
                                        <div className={"flex flex-col lg:flex-col"}>
                                            <div className={"text-my-gray"}>
                                                Data
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="date"
                                                    name={"data"}
                                                    onChange={e => onChange(e)}
                                                    value={data}
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*Bottone*/}
                                    <div className="col-span-2 text-center">
                                        <button
                                            type="submit"
                                            className={"shadow-md py-3 px-6 rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"}
                                        >
                                            Modifica
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </MainLayout>
    );
};

export default AggiungiNotaSpese;
