import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import logoPiccolo from "../../assets/Logo/FWD engineering bluearrow.png";
import Navbar from "../../components/Navbar";
import {useDispatch} from "react-redux";
import {proxyUrl} from "../../constants/proxyUrl";
import axios from "axios";
import {login} from "../../features/authSlice";
import DangerAlert from "../../components/DangerAlert";

const LoginPage = () => {

    const navigate = useNavigate()
    const [errorAlert, setErrorAlert] = useState(false)


    const getToken = async (event) => {
        event.preventDefault();
        const userData = {
            username: event.target.username.value,
            password: event.target.password.value,
        }
        const options = {
            url: `${proxyUrl}/auth/login/`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: userData
        };
        return axios(options)
    };

    const dispatch = useDispatch()

    const handleValidSubmit = (event) => {
        getToken(event).then(r => {
            const token = r.data.access
            const username = r.data.username
            const is_superuser = r.data.is_superuser
            dispatch(login({token: token, username: username, is_superuser: is_superuser}))
            setErrorAlert(false)
            navigate("/")
        }).catch(e => {
            console.log(e)
            setErrorAlert(true)
        })
    }

    return (
        <div>
            <Navbar/>
            <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 px-6 lg:px-8">
                <div className={"sm:mx-auto sm:w-full sm:max-w-md"}>
                    <div className={"flex justify-center items-center"}>
                        <img src={logoPiccolo} alt="" className={"w-40"}/>
                    </div>
                    <h2 className="mt-6 text-center text-2xl font-bold text-gray-900">Effettua l'accesso.</h2>
                </div>
                {errorAlert ? <DangerAlert name={"Errore"} message={"Credenziali non valide"}/> : null}

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-my-white py-8 px-6 shadow rounded-sm sm:px-10">
                        <form className="mb-0 space-y-6" onSubmit={handleValidSubmit}>
                            {/*Email*/}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Username
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="username"
                                        type="text"
                                        required
                                        placeholder={"Username"}
                                        className={"w-full border border-my-gray px-3 py-2 rounded-lg focus:outline-none focus:border-my-gray focus:ring-1"}
                                    />
                                </div>
                            </div>

                            {/*Password*/}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        name="password"
                                        type="password"
                                        required
                                        placeholder={"Password"}
                                        className={"w-full border border-my-gray px-3 py-2 rounded-lg focus:outline-none focus:border-my-gray focus:ring-1"}
                                    />
                                </div>
                            </div>

                            {/*Bottone*/}
                            <div className={"flex items-center justify-center"}>
                                <button
                                    type="submit"
                                    className="shadow-md py-3 w-full justify-center rounded-sm transition duration-300 bg-primary-blue text-white hover:bg-my-white hover:text-black"
                                >
                                    Accedi
                                </button>
                            </div>
                            <div className={"text-center"}>
                                <Link to={"/"}>
                                    <p className={"text-secondary-blue"}>Torna alla Home</p>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LoginPage;
