import React from 'react';

const FoundedInBanner = () => {
    return (
        <div
            className={"text-black divide-black divide-opacity-20 lg:divide-x text-center flex flex-col gap-20 lg:flex-row items-center lg:justify-evenly px-20 lg:py-20 py-10"}>
            <div className={"text-2xl font-bold flex-1"}>
                <div className={"text-4xl"}>
                    30+ ANNI DI PROGETTI INNOVATIVI
                </div>
                <div>
                    1992
                </div>
                <div className={"text-sm font-light"}>
                    Il team di Forward possiede competenze trasversali nei settori della consulenza gestionale, della
                    finanza agevolata ed ordinaria, della pianificazione territoriale e dell’ingegneria che le
                    consentono di elaborare studi di fattibilità e progetti chiavi in mano, con un approccio globale che
                    si è rivelato vincente per trasformare un’idea imprenditoriale in una realtà imprenditoriale,
                    fornendo sempre un servizio su misura.
                </div>
            </div>

        </div>
    );
};

export default FoundedInBanner;
